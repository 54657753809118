import * as React from 'react';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import * as _ from 'lodash';
import LinkTypeSelector from './generic/LinkTypeSelector';
import LinkDisplayInput from './generic/LinkDisplayInput';
import TocSelector from '../../general/toc-selector/TocSelector';
import DocumentPicker, { DocumentPickerType } from './generic/DocumentPicker';
import tocSelectorService, { IDisplayNameResponse } from '../../general/toc-selector/tocSelectorService';
import TextField from 'material-ui/TextField';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import { IProject, ITocNode, IUnitConceptMap, IVariantFamily } from 'mm-types';
import { LinkModel } from './generic/linkService';
import TocStore, { INCREASED_TOC_DEPTH } from '../../../flux/editor/TocStore';
import { getUnitMap, IConceptMap, IUnitConceptMapResponse } from '../../../clients/concepts';
import { isSuccess } from '../../../clients/base-clients';
import CrossReferenceInputs from './generic/CrossReferenceInputs';
import DuRefLinkOptions from './generic/DuRefLinkOptions';

export type Props = {
  model: Partial<LinkModel>;
  originalModel: Partial<LinkModel>;
  showValidation: boolean;
  onUpdateModel: (partial: Partial<LinkModel>) => void;
  onFetching(isFetching: boolean);
  selectedVariantsMap: IUnitConceptMap;
  isHotspot?: boolean;
  isDuRefLink?: boolean;
};

export type State = {
  projectUid?: string;
  indexUid?: string;
  tocs: ITocNode[];
  tocsLoading: boolean;
  variantFamily?: IVariantFamily;
  variantTags?: IConceptMap[];
  selectedTargetVariant?: string | null;
  projectName?: string;
};

export type ExternalCrossReferenceComponentType = {
  isValid(): boolean;
};
const ExternalCrossReferenceComponent = (props: Props, ref: React.RefObject<ExternalCrossReferenceComponentType>) => {
  const inputRef = useRef<LinkDisplayInput | null>(null);
  const docPickerRef = useRef<DocumentPickerType | null>(null);

  const [state, setState] = useState<State>({
    projectUid: props.model.projectUid,
    indexUid: props.model.projectIndexUid,
    tocs: [],
    tocsLoading: false
  });

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return isValid();
    }
  }));

  useEffect(() => {
    fetchTocs();
  }, [props.model.projectUid]);

  useEffect(() => {
    updateToc();
  }, [state.selectedTargetVariant]);

  const isValid = (): boolean => {
    return (!!props.isHotspot || !!props.model.managed || !!inputRef.current?.isValid()) && props.model.elementUid !== undefined;
  };

  const getTitle = () => (props.isHotspot ? 'Select link type and link destination' : 'Select link type and link display text');

  const readOnly = () => false;

  const toggleManaged = (e: React.MouseEvent<HTMLElement>) => {
    props.onUpdateModel({ managed: !props.model.managed });
    e.preventDefault();
    e.stopPropagation();
  };

  const linkDisplayErrorText = () => {
    if (props.showValidation && !props.model.elementUid) {
      return 'Must select a document element';
    }
  };
  const clearSelectedProject = () => {
    setState((prevState) => ({ ...prevState, projectUid: undefined, projectName: undefined, indexUid: undefined, tocs: [] }));
  };

  const selectProject = (selectedProject?: IProject) => {
    const indexUid = selectedProject?.lastPublishedIndexUid;

    // Reset to original model if project selected is the same as original project model or no project selected
    if (selectedProject && selectedProject?.uid === props.originalModel?.projectUid) {
      props.onUpdateModel({ ...props.originalModel, projectIndexUid: indexUid });
    } else {
      // Reset model to default values when changing project
      props.onUpdateModel({
        projectIndexUid: indexUid,
        projectUid: selectedProject?.uid,
        elementUid: undefined,
        elementNid: undefined,
        unitType: undefined,
        selectedVariant: undefined,
        elementName: undefined
      });
    }
    setState((prevState) => ({
      ...prevState,
      projectUid: selectedProject?.uid,
      projectName: selectedProject?.name,
      indexUid: indexUid,
      selectedTargetVariant: null,
      variantFamily: undefined,
      variantTags: undefined
    }));
  };

  const fetchTocs = () => {
    const { projectUid, indexUid } = state;
    if (projectUid && indexUid) {
      props.onFetching(true);
      setState((prevState) => ({ ...prevState, tocsLoading: true }));
      (async () => {
        try {
          const unitVariantMaps = await getUnitMap(indexUid);
          const { children, appendices } = await TocStore.retrieveToc({
            projectUid: projectUid,
            documentIndexUid: indexUid,
            variant: getFirstVariantUid(unitVariantMaps),
            depth: INCREASED_TOC_DEPTH,
            includeParagraphs: true,
            includeCaptionUnits: true
          });
          props.onFetching(false);
          removeAnchorLinks(children);
          setState((prevState) => ({
            ...prevState,
            variantFamily: unitVariantMaps.variantFamily,
            variantTags: unitVariantMaps.conceptTags,
            selectedTargetVariant: props.model.selectedVariant ?? getFirstVariantUid(unitVariantMaps),
            tocs: _.flatten([children, appendices])
          }));
        } finally {
          setState((prevState) => ({ ...prevState, tocsLoading: false }));
        }
        props.onFetching(false);
      })();
    }
  };

  const removeAnchorLinks = (tocs: ITocNode[]) => {
    tocs.map((toc) => {
      toc.children = toc.children.filter((c) => c.type !== 'anchor');
      removeAnchorLinks(toc.children);
    });
  };

  const getFirstVariantUid = (unitVariantMaps: IUnitConceptMapResponse) => {
    if (props.model && props.model.selectedVariant) {
      return props.model.selectedVariant;
    }

    return unitVariantMaps.variantFamily && unitVariantMaps.variantFamily.concepts?.length > 0
      ? unitVariantMaps?.variantFamily.concepts?.[0].uid
      : null;
  };
  const projectName = () => {
    if (docPickerRef.current) {
      const project = docPickerRef.current.getSelectedProject();
      if (project) {
        return ': ' + project.name;
      }
    }
  };

  const onVariantSelected = (event, index, value) => {
    setState((prevState) => ({ ...prevState, selectedTargetVariant: value }));
  };

  const updateToc = () => {
    props.onFetching(true);
    setState((prevState) => ({ ...prevState, tocsLoading: true }));
    (async () => {
      try {
        if (state.projectUid && state.indexUid) {
          const { children, appendices } = await TocStore.retrieveToc({
            projectUid: state.projectUid,
            documentIndexUid: state.indexUid,
            variant: state.selectedTargetVariant || null,
            depth: INCREASED_TOC_DEPTH,
            includeParagraphs: true,
            includeCaptionUnits: true
          });
          setState((prevState) => ({ ...prevState, tocsLoading: false, tocs: _.flatten([children, appendices]) }));
        } else {
          setState((prevState) => ({ ...prevState, tocsLoading: false, tocs: [] }));
        }
        props.onFetching(false);
      } catch {
        setState((prevState) => ({ ...prevState, tocsLoading: false }));
        props.onFetching(false);
      }
    })();
  };

  const sectionSelect = (element: ITocNode) => {
    props.onFetching(true);
    let displayName = '';
    // join these as 1 call

    if (state.projectUid && state.indexUid) {
      tocSelectorService
        .displayName(state.projectUid, state.indexUid, element, false, state.selectedTargetVariant!)
        .then((response) => {
          props.onFetching(false);
          if (isSuccess<IDisplayNameResponse>(response)) {
            displayName = response.text;
          }
        })
        .finally(() => {
          props.onFetching(false);
          props.onUpdateModel({
            elementUid: element?.uid,
            elementNid: element?.elementNid,
            unitType: element?.type,
            selectedVariant: state.selectedTargetVariant ?? undefined,
            elementName: displayName
          });
        });
    }
  };

  return (
    <div className="link-modal-body-cross-doc">
      <div className="link-modal-col-1">
        <h3>{getTitle()}</h3>

        <div className="input">
          <LinkTypeSelector value={props.model?.type} onChange={(type) => props.onUpdateModel({ type: type })} />
        </div>

        {!props.isHotspot && !props.isDuRefLink && (
          <CrossReferenceInputs
            model={props.model}
            readOnly={readOnly}
            showValidation={props.showValidation}
            onUpdateModel={(partial) => props.onUpdateModel(partial)}
            toggleManaged={(e) => toggleManaged(e)}
            isHotspot={props.isHotspot}
            refs={inputRef}
            isExternal
          />
        )}
        {props.isDuRefLink && <DuRefLinkOptions model={props.model} onUpdateModel={(partial) => props.onUpdateModel(partial)} />}
        <div className="input">
          <TextField
            value={props.model.elementName}
            id="link-destination-input"
            disabled={true}
            multiLine={true}
            errorText={linkDisplayErrorText()}
            floatingLabelText="Link Destination"
          />
        </div>
      </div>

      <div className="link-modal-col-2">
        <h3>Select Document</h3>
        <DocumentPicker
          hideExternal={true}
          onFetching={(isFetching) => props.onFetching(isFetching)}
          selectedProjectUid={props.model.projectUid}
          clearSelectedProject={() => clearSelectedProject()}
          ref={docPickerRef}
          onSelectProject={(p) => selectProject(p)}
          isDuRefLink={props.isDuRefLink}
        />
      </div>

      <div className="link-modal-col-3">
        <h3>Select Link {projectName()}</h3>
        {state.variantFamily ? (
          <div className="variant-selector">
            <label>Select Document Variant:</label>
            <DropDownMenu value={state.selectedTargetVariant} onChange={(event, index, value) => onVariantSelected(event, index, value)}>
              {state.variantFamily?.concepts?.map((v, index) => {
                return <MenuItem key={'variant-' + index} value={v.uid} primaryText={v.name} />;
              })}
            </DropDownMenu>
          </div>
        ) : null}

        <TocSelector
          variantTags={state.variantTags}
          tocs={state.tocs}
          allowVariantSelection={true}
          variantsMustMatch={false}
          selectedVariantsMap={props.selectedVariantsMap}
          tocsLoading={state.tocsLoading}
          onSelect={(element) => sectionSelect(element)}
          selectedUid={props.model.elementUid + (props.model.elementNid || '')}
          documentIndexUid={state.indexUid}
          isDuRefLink={props.isDuRefLink}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(ExternalCrossReferenceComponent);
